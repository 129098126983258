import React, { useState } from "react";

import "./App.css";

const images = [
    { id: 1, name: "collage-1.jpg" },
    { id: 2, name: "collage-2.jpg" },
    { id: 3, name: "collage-3.jpeg" },
    { id: 4, name: "collage-4.jpeg" },
    { id: 5, name: "collage-5.jpg" },
    { id: 6, name: "collage-6.jpeg" },
    { id: 7, name: "collage-7.jpg" },
    { id: 8, name: "collage-8.jpg" },
    { id: 9, name: "collage-9.jpg" },
    { id: 10, name: "collage-10.jpg" },
    { id: 11, name: "collage-11.jpeg" },
    { id: 12, name: "collage-12.jpg" },
];

const App = () => {
    const [menu_open, setMenuOpen] = useState(false);

    const scrollTo = (id) => {
        const el = document.getElementById(id);
        el.scrollIntoView();
    };

    return (
        <div className="app">
            {menu_open && (
                <div
                    onClick={() => setMenuOpen(false)}
                    className="click-container"
                ></div>
            )}
            <div className="header">
                <div
                    onClick={() => setMenuOpen(!menu_open)}
                    className={`hamburger hamburger--slider ${
                        menu_open ? "is-active" : ""
                    }`}
                >
                    <div className="hamburger-box">
                        <div className="hamburger-inner"></div>
                    </div>
                </div>
                <span>Drip City</span>
            </div>
            <div className={`menu ${menu_open ? "open" : ""}`}>
                <span onClick={() => scrollTo("logo-container")}>Home</span>
                <span onClick={() => scrollTo("about")}>About</span>
                <span onClick={() => scrollTo("gallery")}>Gallery</span>
            </div>
            <div className="inner">
                <div id="logo-container" className="container">
                    <img id="logo" src="./logo.png" alt="Logo" />
                </div>
                <div id="about" className="container">
                    <span>
                        Drip City is een wonderlijke plaats in Veenendaal waar
                        de meest geniale jongens wonen. Er is ruimte voor
                        gezelligheid en een goed gesprek. De fasciliteiten die
                        geboden worden worden hartelijk ontvangen door de
                        gasten. De take-and-jets policy wordt goed in acht
                        genomen en door iedereen opgevolgd. Het belangrijkste
                        wat we als collective Drip City mee willen geven is om
                        lekker jezelf te zijn, want dan ben je op je best 🥰.
                    </span>
                </div>
                <div id="gallery" className="container">
                    <div className="masonry">
                        {images.map(({ id, name }) => (
                            <div>
                                <img key={id} src={`./images/${name}`} alt="" />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default App;
